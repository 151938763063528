import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Row, Button } from "react-bootstrap";
import "./ServiceRequestDetails.scss";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Calendar } from "primereact/calendar";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const ServiceRequestDetails = (props) => {
  let history = useNavigate();
  //Estimate-Cancel Modal
  const [showEstimate, setEstimate] = useState(false);
  const handleEstimateClose = () => setEstimate(false);
  const handleEstimateShow = () => setEstimate(true);

  //Estimate Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Input comments Modal
  const [showInput, setShowInput] = useState(false);
  const handleCloseInput = () => setShowInput(false);
  const handleShowInput = () => setShowInput(true);

  //Work Report Modal
  const [showReport, setShowReport] = useState(false);
  const handleCloseReport = () => setShowReport(false);
  const handleShowReport = () => setShowReport(true);

  //   const [date, setDate] = useState(new Date());
  let today = new Date();
  let month = today.getMonth();
  let year = today.getFullYear();
  let prevMonth = month === 0 ? 11 : month - 1;
  let prevYear = prevMonth === 11 ? year - 1 : year;
  let nextMonth = month === 11 ? 0 : month + 1;
  let nextYear = nextMonth === 0 ? year + 1 : year;

  const [date, setDate] = useState(null);

  const openEstimateModal = () => {
    setEstimate(true);
  };
  const closeEstimateSelection = () => {
    setEstimate(false);
    setShow(true);
  };

  // closeEstimateSelectionToComment
  const closeEstimateSelectionToComment = () => {
    setEstimate(false);
    setShowInput(true);
  };
  const goBack = () => {
    history(-1);
  };
  return (
    <div className="service-details-wrapper">
      <div className="header-section">
        <span
          onClick={() => goBack()}
          className="backIcon"
          style={{ color: "blue" }}
        >
          <MdArrowBack />
        </span>
        <h5 className="app-header">TRITON GUARD</h5>
      </div>

      <div className="title">
        <div className="request-header">Issue Details</div>
        <div className="welcome">
          <div className="user-box">
            <img src="https://picsum.photos/200/300?random=3" alt="" />
          </div>
          <div className="vessel-name">Vessel 1</div>
          <div className="vessel-number">IMO : 12345</div>
        </div>
      </div>
      <div className="issue-description-wrapper">
        <Row className="issue-container">
          <div className="issue-description">Abnormal Sound from Engine</div>
          <div className="part-specification">Main Engine</div>
          <div className="issue-time-status">
            <div className="issue-timestamp">5th July 2021</div>
            <div className="issue-status-description-details">
              Pending with Service Provider
            </div>
          </div>
          <div className="detailed-description">
            <div className="description-header">Description</div>
            <div className="description-details">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
            <div className="description-images-complete">
              <img
                src="https://picsum.photos/200/300?random=1"
                alt="image1"
                width="100"
                height="50"
              ></img>
              <img
                src="https://picsum.photos/200/300?random=2"
                alt="image2"
                width="100"
                height="50"
              ></img>
            </div>
          </div>
        </Row>
      </div>
      <div className="workflow-wrapper">
        <div className="workflow-header">Workflow</div>
        <div className="workflow-steps">
          <div className="step-summary active">
            <div className="worflow-diagram">
              <div className="workflow-round"></div>
              <div className="workflow-arrow"></div>
            </div>
            <div className="step-details">
              <div className="step-description">Estimate Time and Date</div>
              <div className="step-timestamp">5th May 2021,5:20 PM</div>
            </div>
            <div className="step-approval">
              <div className="step-approver-details">
                Julia ,Service Provider
              </div>
              <div className="step-status pending">
                <Button className="pending-button" onClick={handleEstimateShow}>Take Action</Button></div>
            </div>
          </div>
        </div>
      </div>
      <div className="pending-steps-wrapper">
        <div className="task-header">To be started</div>
        <div className="task-list-body">
          <div className="task-list-details">
            <div className="workflow-round"></div>
            <div className="task-name">Remote Access Approval</div>
          </div>
          <div className="task-list-details">
            <div className="workflow-round"></div>
            <div className="task-name">Report Work</div>
          </div>
          <div className="task-list-details">
            <div className="workflow-round"></div>
            <div className="task-name">Review Work</div>
          </div>
        </div>
      </div>
      <div className="estimate-modal-wrapper">
        <Modal
          className="estimate-modal"
          show={show}
          onHide={handleClose}
          animation={false}
          backdrop={false}
          style={{ background: "#0000004d", height: "26", zIndex: "100" }}
        >
          <Modal.Header closeButton>WORK ESTIMATION</Modal.Header>
          <Modal.Body>
            {/* <div className="request-estimate-header"></div> */}
            <Form.Label>Estimate Date & Time</Form.Label>
            <div className="field">
              <Calendar
                id="time24"
                value={date}
                onChange={(e) => setDate(e.value)}
                selectionMode="range"
                showTime
                showSeconds
              />
            </div>
            <Form.Group className="mb-0 comment-text" controlId="ControlTextarea">
              <Form.Label>Comments</Form.Label>
              <Form.Control as="textarea" rows={5} />
            </Form.Group>
          </Modal.Body>
          <div className="comment-button-group">
            <Button variant="secondary close-form" onClick={handleClose}>
              CANCEL
            </Button>
            <Button variant="secondary submit-approval" onClick={handleClose}>
              SUBMIT
            </Button>
          </div>
        </Modal>
      </div>
      <div className="approve-modal-wrapper">
        <Modal
          className="approve-modal"
          animation={false}
          show={showEstimate}
          onHide={handleEstimateClose}
          backdrop={false}
          style={{ background: "#0000004d", height: "26" }}
        >
          <Modal.Header closeButton>SELECT AN ACTION</Modal.Header>
          <Modal.Body>
            <div className="approve-deny-button-group">
              <Button
                variant="secondary deny-button"
                onClick={() => closeEstimateSelectionToComment()}
              >
                SEND BACK
              </Button>
              <Button
                variant="secondary approve-button"
                onClick={() => closeEstimateSelection()}
              >
                ESTIMATE
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <div className="comment-modal-wrapper">
        <Modal
          className="comment-modal"
          show={showInput}
          onHide={handleCloseInput}
          animation={false}
          backdrop={false}
          style={{ background: "#0000004d", height: "26" }}
        >
          <Modal.Header closeButton>COMMENTS</Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-0" controlId="ControlTextarea">
              <Form.Control as="textarea" rows={3} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <div className="comment-button-group">
              <Button variant="secondary close-form" onClick={handleCloseInput}>
                CANCEL
              </Button>
              <Button
                variant="secondary submit-approval"
                onClick={handleCloseInput}
              >
                SUBMIT
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default ServiceRequestDetails;
