import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/LargeLogo.png";
import { IoMdLogOut } from "react-icons/io";
import { MdArrowBack } from "react-icons/md";
import { HiMenu } from "react-icons/hi";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import "./NavigationMenu.scss";
import {
  useMsal
} from "@azure/msal-react";

const Sidenav = ({ ...props }) => {
  const [closeBar, setCloseBar] = useState(true);
  const { instance} = useMsal();

  let history = useNavigate();

  useEffect(() => {
  }, [props.bgColor]);

  const goBack = () => {
    history.goBack();
  };

  const userLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  const BackButton = () => {
    if (window.location.hash !== "#/serviceRequestList") {
      return (
        <span
          onClick={() => goBack()}
          className="backIcon"
          style={{ color: props.bgColor }}
        >
          <MdArrowBack />
        </span>
      );
    } else {
      return null;
    }
  };
  return (
    <>
      <BackButton />
      <span
        onClick={() => setCloseBar(false)}
        className="menuIcon"
        style={{ color: props.bgColor }}
      >
        <HiMenu />
      </span>
      {!closeBar ? (
        <div className="modal-window" onClick={() => setCloseBar(true)}></div>
      ) : null}
      <ProSidebar
        collapsed={closeBar}
        collapsedWidth={"0px"}
        className="right-bar"
      >
        <SidebarHeader>
          <div className="head">
            <img className="logo" src={logo} alt="logo" />
            <h1 className="title">GUARD</h1>
          </div>
        </SidebarHeader>
        <hr />
        <SidebarContent>
          <Menu className="menu">
            <MenuItem
            >
            </MenuItem>

            <MenuItem
              icon={<IoMdLogOut className="icon" />}
              onClick={userLogout}
            >
              Logout
            </MenuItem>
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </>
  );
};

export default Sidenav;
