import React, {
  Component,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import { ReactDOM } from "react-dom";
import "./ServiceRequestList.scss";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Button as Pbutton } from "primereact/button";
import { BsFillLockFill } from "react-icons/bs";
import { TabView, TabPanel } from "primereact/tabview";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import SelectBox from "../Select/Select.js";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import Sidenav from "../NavigationMenu/Sidenav";
import { TailSpin } from "react-loader-spinner";
import { Toast } from "primereact/toast";
import CloseButton from "react-bootstrap/CloseButton";
import { AuthContext } from "../../providers/AuthProvider.js";
import { Paginator } from "primereact/paginator";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";
import moment from "moment";
import {
  useQuery,
  gql,
  useLazyQuery,
  ApolloProvider,
  useMutation,
} from "@apollo/client";
import { client } from "../../services/GraphqlService";
import { Vessel_query } from "./vesselQuery";
import { Link } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { validate } from "graphql";

const Customer_query = gql`
  query {
    customersByUser {
      customerDisplayName
      customerName
      customerId
    }
  }
`;

const Category_query = gql`
  query {
    serviceRecordTypes {
      name
      description
      code
    }
  }
`;

const ADD_NEW_REQUEST = gql`
  mutation upsertServiceRecord(
    $vesselId: Int!
    $custId: Int!
    $serviceRecordInput: ServiceRecordInput!
  ) {
    upsertServiceRecord(
      vesselId: $vesselId
      custId: $custId
      serviceRecordInput: $serviceRecordInput
    )
  }
`;

const ServiceRequestList = (props) => {
  let history = useNavigate();
  const { instance } = useMsal();
  const toast = useRef(null);
  const [cId, setCustomerData] = useState("");
  const [show, setShow] = useState(false);
  const [showSubmitProgress, setProgress] = useState(false);
  const [customerList, getCustomersList] = useState([]);
  const [customerListForMapping, getCustomersListMapping] = useState([]);
  const [vesselList, getVesselList] = useState([]);
  const [vesselListForm, getVesselListForm] = useState([]);
  const [vesselListForMapping, getVesselListMapping] = useState([]);
  const [titleValue, setTitleValue] = useState("");
  const [commentValue, setCommentValue] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [selectedVessel, setSelectedVessel] = useState({});
  const [serviceRecordList, setServiceRecords] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [authorised, setAuthorisation] = useState(true);
  const [date, setDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const handleClose = () => setShow(false);
  const handleDetailClose = () => setDetail(false);
  const [first, setFirst] = useState(1);
  const [rows, setRows] = useState(10);
  const [paginationOption, setpaginationOption] = useState([]);
  const [checkFields, setValidationCheck] = useState(false);
  const [details, setDetail] = useState(false);
  const [requestNumber, setRequestNumber] = useState("");
  const [currentWorkFlow, setCurrentWorkFlow] = useState("");
  const [newWorkFlow, setNewWorkFlow] = useState("");
  const [assigneeRole, setAssigneeRole] = useState("");

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let errors = {};

    if (!titleValue.trim()) {
      errors.description = "Description is required";
    }

    if (!cId) {
      errors.customer = "Customer is required";
    }

    if (!selectedVessel) {
      errors.vessel = "Vessel is required";
    }


    if (!date) {
      errors.date = "Date is required";
    } else if (!startDate || !date[0]) {
      errors.date = "Start Date is required";
    } else if (!endDate || ![date[1]]) {
      errors.date = "End Date is required";
    }

    if(date[0] && date[1] && (date[0]>date[1])){
      errors.date = "Start Date is greater than End Date";
    }

    if (date[0] && date[1]) {
      var startTime = moment(date[0], "DD-MM-YYYY hh:mm:ss");
      var endTime = moment(date[1], "DD-MM-YYYY hh:mm:ss");

      var minutesDiff = endTime.diff(startTime, "minutes");
      console.log("Minutes:" + minutesDiff);

      var secondsDiff = endTime.diff(startTime, 'seconds');    
      console.log('Seconds:' + secondsDiff);

      if (minutesDiff == 0 && secondsDiff ==0) {
        errors.date = "Start Date and End Date cannot be same";
      }
    }

    setErrors(errors);
    if (
      titleValue &&
      selectedVessel.code &&
      selectedCustomer.code &&
      date[0] &&
      date[1] &&
      date[1] > date[0] && !errors.date
    ) {
      submitDetails();
    }
    return Object.keys(errors).length === 0;
  };

  const showToaster = () => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Request Submitted Successfully",
      life: 6000,
    });
  };

  const showDateTime = () => {
    toast.current.show({
      severity: "warn",
      summary: "Warn Message",
      detail: "Desired Start Time Should greater than Desired End Date",
      life: 5000,
    });
  };

  const onPageChange = (event) => {
    setFirst(1);
    setRows(event.rows);
    if (isDisabled) {
      getServiceRecords();
    }
  };

  const Service_query = gql`
  query {
    serviceRecordList (pageNumber:${first},pageSize:${rows}) {
      requestId,
      serviceRequestTypeCode,
      comments,
      description,
      createdDate,
      status,
      estimateStartDate,
      estimateEndDate,
      customer,
      vessel,
      currentAssigneeJob,
      currentWorkFlow,
      nextWorkFlow,
      requestStatus
    }
  }
`;

  useEffect(() => {
    setFirst(1);
    setRows(10);
    getServiceRecords();
    getCustomers();
    cancelAction();
    setDate("");
    setCustomerData("");
    setSelectedCustomer({});
  }, []);

  let today = new Date();
  let minDate = new Date();
  let month = today.getMonth();
  let year = today.getFullYear();
  let hour = today.getHours();
  let prevMonth = month === 0 ? 11 : month - 1;
  let prevYear = prevMonth === 11 ? year - 1 : year;
  minDate.setMonth(prevMonth);
  minDate.setFullYear(prevYear);
  minDate.setHours(hour + 2);

  const showToasterFailure = () => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: "Request Submission Failed",
      life: 5000,
    });
  };

  const showToasterFailureMessage = (errorMessage) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: errorMessage,
      life: 5000,
    });
  };

  const template = {
    layout: "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 30, value: 30 },
      ];

      return (
        <Dropdown
          value={options.value}
          options={dropdownOptions}
          onChange={options.onChange}
          placeholder={dropdownOptions[0].label}
        />
      );
    },
  };

  const submitDetails = () => {
    setProgress(true);
    setIsDisabled(true);
    let formdetails = {
      serviceTypeCode: "RAR",
      description: titleValue,
      comments: commentValue,
      requestId: 0,
      workflowCode: "ACCESS_REQUEST_RAISED",
      statusCode: "COMPLETED",
      estimateStartDate: moment(date[0]).utc().valueOf(),
      estimateEndDate: moment(date[1]).utc().valueOf(),
      assignToRole: "CPN",
      assignToUser: null,
    };
    addNewRequest({
      variables: {
        vesselId: selectedVessel.code,
        custId: selectedCustomer.code,
        serviceRecordInput: formdetails,
      },
    });
  };

  const cancelAction = () => {
    setTitleValue("");
    setCommentValue("");
    setSelectedCustomer({});
    setSelectedVessel("");
    setDate("");
    handleClose();
    setProgress(false);
    setShow(false);
    setIsDisabled(false);
    setErrors({});
    setCustomerData("");
  };

  const userLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  const goToServiceDetails = () => {
    history(`/serviceDetails`);
  };

  const goToReportWork = () => {
    history(`/serviceRequestForm`);
  };

  const goToClosedTicket = () => {
    history(`/serviceDetailsResolved`);
  };

  const openRemoteAccessForm = () => {
    cancelAction();
    setShow(true);
    setIsDisabled(false);
    getCustomers();
    getVesselListForm([]);
  };

  const getTimestamp = (timestamp) => {
    let d = timestamp / 1000;
    return moment.unix(d).format("DD-MM-YYYY");
  };

  const [getCustomers, { loading, error, data }] = useLazyQuery(
    Customer_query,
    {
      fetchPolicy: "network-only",
      onCompleted(resp) {
        if (resp && resp.customersByUser) {
          let modifiedList = [];
          resp.customersByUser.map((cust) => {
            modifiedList.push({
              name: cust.customerDisplayName != null && cust.customerDisplayName.trim()!= '' ?cust.customerDisplayName : cust.customerName,
              code: cust.customerId,
            });
            getCustomersList(modifiedList);
            getCustomersListMapping(modifiedList);
          });
        }
      },
      onError(err) {
        setAuthorisation(false);
      },
    }
  );

  const [
    getVessels,
    { loading: vesselLoading, error: vesselerror, data: vesseldata },
  ] = useLazyQuery(Vessel_query, {
    fetchPolicy: "cache-and-network",
    onCompleted(resp) {
      let modifiedVesselList = [];
      resp.vesselsByCustomer.map((vessel) => {
        if (show) {
          modifiedVesselList.push({
            name: vessel.displayName,
            code: vessel.id,
          });
          getVesselListForm(modifiedVesselList);
        }
        getVesselListMapping(resp.vesselsByCustomer);
      });
    },
  });

  const [
    getServiceRecords,
    { loading: serviceloading, error: servicerror, data: serviceRecord },
  ] = useLazyQuery(Service_query, {
    fetchPolicy: "network-only",
    onCompleted(resp) {
      if (resp.serviceRecordList) {
        setServiceRecords(resp.serviceRecordList);
      }
    },
  });

  const [
    addNewRequest,
    {
      loading: newrequestloading,
      error: newrequesterror,
      data: newrequestdata,
    },
  ] = useMutation(ADD_NEW_REQUEST, {
    fetchPolicy: "network-only",
    onCompleted(resp) {
      if (resp.upsertServiceRecord === "Success") {
        showToaster();
        handleClose();
        setIsDisabled(false);
        setErrors({});
        getServiceRecords();
      } else {
        showToasterFailure();
        cancelAction();
      }
    },
    onError(err) {
      console.log("err", err);
      showToasterFailureMessage(err.message);
      cancelAction();
    },
  });

  const populateVessel = (event, errors) => {
    setCustomerData(event.value);
    setSelectedCustomer(event.value);
    if (event.value !== "") {
      errors.customer = "";
    } else {
      errors.customer = "Customer is required";
    }
    getVesselListForm([]);
    getVessels({ variables: { id: event.value.code } });
  };

  const populateTitleValue = (event, errors) => {
    setTitleValue(event.target.value);
    if (event.target.value !== "") {
      errors.description = "";
    } else {
      errors.description = "Description is required";
    }
  };

  const populateCommentValue = (event) => {
    setCommentValue(event.target.value);
  };

  const populateDate = (event) => {
    setDate(event.value);
    setStartDate(event.value[0]);
    setEndDate(event.value[1]);

    if (event.value !== "") {
      errors.date = "";
    }

    if (event.value[0] == null && event.value[1]==null) {
      errors.date = "Date is required";
    }
    
    if (event.value[1]==null && event.value[0] !==null) {
      errors.date = "End Date is required";
    }

    if (event.value[0] && event.value[1]) {
      var startTime = moment(event.value[0], "DD-MM-YYYY hh:mm:ss");
      var endTime = moment(event.value[1], "DD-MM-YYYY hh:mm:ss");

      var minutesDiff = endTime.diff(startTime, "minutes");

      var secondsDiff = endTime.diff(startTime, 'seconds');    

      if (minutesDiff == 0 && secondsDiff ==0) {
        errors.date = "Start Date and End Date cannot be same";
      }

      if(startTime.diff(endTime)>0){
        showDateTime();
      }
    }
  };

  const showDetail = (record) => {
    if (record.currentAssigneeJob !== "Captain") {
      setDetail(true);
      let requestDetailNumber =
        record.serviceRequestTypeCode + "-" + record.requestId;
      setRequestNumber(requestDetailNumber);
      setCurrentWorkFlow(record.currentWorkFlow);
      setNewWorkFlow(record.nextWorkFlow);
      setAssigneeRole(record.currentAssigneeJob);
    }
  };

  const validationVesselCheck = (e) => {
    setSelectedVessel(e.value);
    if (e.value !== "") {
      errors.vessel = "";
    } else {
      errors.vessel = "Vessel is required";
    }
  };

  const [bgColor, setBgColor] = useState("#224691");

  return authorised ? (
    <div className="service-request-wrapper">
      {serviceloading && (
        <div className="loader">
          <TailSpin
            color=" #2546B1"
            height={80}
            width={80}
            timeout={300000}
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass="loader"
            visible={true}
          />
        </div>
      )}

      {!serviceloading && (
        <>
          <div className="header-wrapper">
            <div className="app-header">TRITON GUARD</div>
            <div className="service-request-header">SERVICE REQUESTS</div>
          </div>
          <Sidenav bgColor={bgColor}></Sidenav>
          <div className="service-request-details-wrapper">
            <TabView>
              <TabPanel header="Raised">
                {serviceRecordList.length > 0 ? (
                  serviceRecordList.map((record, i) => (
                    <div className="service-details-summary" key={i}>
                      <div className="issue-details-wrapper remote-access">
                        <div className="issue-description remote-access">
                          {record.serviceRequestTypeCode} - {record.requestId} -{" "}
                          {record.description}
                        </div>
                        <div className="vessel-details remote-access-customer">
                          {record.customer} - {record.vessel}
                        </div>
                        <div className="issue-timestamp remote-date">
                          {getTimestamp(record.estimateStartDate)}-{" "}
                          {getTimestamp(record.estimateEndDate)}
                        </div>
                        <div className="issue-status remote-status">
                          {record.currentWorkFlow == "Access Request Raised" ? (
                            <span className="pending">
                              Pending with Approver
                            </span>
                          ) : (
                            <div>
                              {record.requestStatus == "Completed" ? (
                                <>
                                  <p
                                    onClick={() => showDetail(record)}
                                    className="approved"
                                  >
                                    <FaInfoCircle className="info-icon"></FaInfoCircle>{" "}
                                    Access Request Approved
                                  </p>
                                </>
                              ) : (
                                <>
                                  <span className="pending">
                                    Access Request Denied
                                  </span>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-record">No Records Found</div>
                )}
              </TabPanel>
              <TabPanel header="Assigned" disabled>
                <div
                  className="service-details-summary"
                  onClick={() => goToServiceDetails()}
                >
                  <div className="vessel-image">
                    <div className="user-box">
                      <img
                        src="https://picsum.photos/200/300?random=1"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="issue-details-wrapper assigned">
                    <div className="vessel-details">Vessel 1-Customer 1</div>
                    <div className="issue-description">
                      Abnormal Sound from Engine
                    </div>
                    <div className="issue-timestamp">5th May 2022</div>
                    <div className="issue-status assigned-status">Pending</div>
                  </div>
                </div>
                <div
                  className="service-details-summary"
                  onClick={() => goToReportWork()}
                >
                  <div className="vessel-image">
                    <div className="user-box">
                      <img
                        src="https://picsum.photos/200/300?random=2"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="issue-details-wrapper assigned">
                    <div className="vessel-details">Vessel 2-Customer 2</div>
                    <div className="issue-description">
                      Maintenance Upgrade required
                    </div>
                    <div className="issue-timestamp">5th May 2022</div>
                    <div className="issue-status in-progress assigned-status">
                      Work in Progress
                    </div>
                  </div>
                </div>
                <div
                  className="service-details-summary"
                  onClick={() => goToClosedTicket()}
                >
                  <div className="vessel-image">
                    <div className="user-box">
                      <img
                        src="https://picsum.photos/200/300?random=3"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="issue-details-wrapper assigned">
                    <div className="vessel-details">Vessel 3-Customer 3</div>
                    <div className="issue-description">
                      Maintenance Upgrade required
                    </div>
                    <div className="issue-timestamp">5th May 2022</div>
                    <div className="issue-status resolved assigned-status">
                      Resolved
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabView>
          </div>
          <div className="service-request-remote-access">
            <div className="card">
              {serviceRecordList.length > 0 && (
                <Paginator
                  template={template}
                  first={first}
                  rows={rows}
                  totalRecords={serviceRecordList.length}
                  rowsPerPageOptions={[10, 20, 30]}
                  onPageChange={onPageChange}
                ></Paginator>
              )}
            </div>
            <Button
              className="request-access-button"
              onClick={() => openRemoteAccessForm()}
            >
              Request Access
            </Button>
          </div>
        </>
      )}

      <div className="access-form-modal-wrapper">
        {loading && (
          <div className="loader">
            <TailSpin color=" #2546B1" height={80} width={80} timeout={30000} />
          </div>
        )}
        {customerList.length > 0 && (
          <Modal
            className="access-form-modal"
            show={show}
            onHide={handleClose}
            animation={false}
            backdrop={false}
            style={{ background: "#0000004d" }}
          >
            <Modal.Header>
              NEW ACCESS REQUEST{" "}
              <CloseButton onClick={cancelAction}></CloseButton>
            </Modal.Header>
            <Modal.Body>
              <Form.Group
                className="mb-2 work-report-title"
                controlId="formBasicIssueTitle"
              >
                <Form.Label className="form-label title">
                  Description
                </Form.Label>
                <Form.Control
                  onChange={(e) => populateTitleValue(e, errors)}
                  disabled={isDisabled}
                  autoComplete="off"
                  maxLength={50}
                  className={"" + (errors.description ? "error-highlight" : "")}
                />
                {errors.description && (
                  <span class="error-msg">{errors.description}</span>
                )}
              </Form.Group>
              <Form.Label className="form-label select-title ">
                Customer
              </Form.Label>
              <Dropdown
                value={selectedCustomer}
                onChange={(e) => populateVessel(e, errors)}
                options={customerList}
                optionLabel="name"
                placeholder="Select Customer"
                className={
                  "w-full md:w-14rem " +
                  (errors.customer ? "error-highlight" : "")
                }
                disabled={isDisabled}
              />
              {errors.customer && (
                <span class="error-msg float-customer">{errors.customer}</span>
              )}
              <Form.Label className="form-label select-title vessel">
                Vessel
              </Form.Label>
              <Dropdown
                value={selectedVessel}
                onChange={(e) => validationVesselCheck(e)}
                options={vesselListForm}
                optionLabel="name"
                placeholder="Select Vessel"
                className={
                  "w-full md:w-14rem " +
                  (errors.vessel ? "error-highlight" : "")
                }
                disabled={isDisabled}
              />
              {errors.vessel && <span class="error-msg">{errors.vessel}</span>}
              <Form.Label className="select-title">
                Access Date Time Range
              </Form.Label>
              <div className="field">
                <Calendar
                  id="time24"
                  value={date}
                  onChange={(e) => populateDate(e)}
                  selectionMode="range"
                  showTime
                  showSeconds
                  disabled={isDisabled}
                  minDate={today}
                  readOnlyInput
                  className={
                    "remote-calendar " + (errors.date ? "error-highlight" : "")
                  }
                />
              </div>
              {errors.date && <span class="error-msg">{errors.date}</span>}
              <Form.Group
                className="mb-0 work-report-description"
                controlId="ControlTextarea"
              >
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  onChange={(e) => populateCommentValue(e)}
                  disabled={isDisabled}
                  maxLength={250}
                />
              </Form.Group>
            </Modal.Body>
            <div className="comment-button-group">
              <Button variant="secondary close-form" onClick={cancelAction}>
                CANCEL
              </Button>
              {!showSubmitProgress ? (
                <Button
                  variant="secondary submit-approval"
                  onClick={validateForm}
                >
                  SUBMIT
                </Button>
              ) : (
                <Pbutton
                  label="Submit"
                  icon="pi pi-spin pi-spinner"
                  iconPos="right"
                  className="submit-approval"
                />
              )}
            </div>
          </Modal>
        )}
      </div>
      <div className="detail-modal-wrapper">
        <Modal
          className="detail-modal"
          show={details}
          onHide={handleDetailClose}
          animation={false}
          backdrop={false}
          style={{ background: "#0000004d" }}
        >
          <Modal.Header>
            REQUEST DETAILS{" "}
            <CloseButton onClick={handleDetailClose}></CloseButton>
          </Modal.Header>
          <Modal.Body>
            <div className="email">
              Email with remote access request details, has been sent
              successfully to the requestor for the Request:
              <b>{requestNumber}</b>.
            </div>
            <div className="next">
              <FaInfoCircle className="info-icon blue"></FaInfoCircle>{" "}
              <b>{currentWorkFlow}</b> has been Completed. <b>{newWorkFlow}</b>{" "}
              on this request is pending with {assigneeRole}.
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <Toast ref={toast} />
    </div>
  ) : (
    <div className="unauthorized">
      <div className="center-align">
        <p>
          You are not authorised to view this application. Please contact admin
          for access
        </p>
        <div className="link" onClick={userLogout}>
          Go to login
        </div>
      </div>
    </div>
  );
};

export default ServiceRequestList;
