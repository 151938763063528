import React, { useEffect } from "react";
import { Toast, Button, Container, Row, Col, Modal } from "react-bootstrap";
import * as serviceWorker from './serviceWorkerRegistration';
import "./index.css";

interface Props {
  children: React.ReactNode
}

const ServiceWorkerWrapper:  React.FC<Props> = ({children}): JSX.Element => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] =
    React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    window.location.reload();
  };

  return (
    <div className="toast-wrap">
      <Modal show={showReload} centered style={{color: '#2546b1'}}>
        <Modal.Header>
          <Modal.Title>Update available!</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{color: '#2546b1'}}>
          <Row>
            <Col xs={8}>
              <div className="text-left">Please update to proceed.</div>
            </Col>
            <Col xs={4}>
              <Button onClick={reloadPage}>Update</Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {children}
    </div>
  );
};

export default ServiceWorkerWrapper;
