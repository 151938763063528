import React, {Component,useState} from "react";
// import AuthService from "../services/AuthService.ts"

 export const AuthContext = React.createContext();

// export const AuthConsumer = AuthContext.Consumer;

export class AuthProvider extends Component {
    // authService;
    // url;
    constructor(props) {
        super(props);
        // this.authService = new AuthService();
        // this.authService.getUser().then((user) => {
        //     if (user) {
        //         localStorage.setItem('user_email',user.profile.email)
        //     }
        //   });

        // this.state = {config: {}}
    }
    componentDidMount(){
        if(window.location.href.indexOf("3000") !== -1 || window.location.href.indexOf("white") !== -1 ){
            this.url ='/appConfig/contextpath.json'
        }
        else if (window.location.href.indexOf("yellow") !== -1 || window.location.href.indexOf("acc") !== -1 ){
            this.url ='/appConfig/contextpath-acc.json'
        }
        else {
            this.url ='/appConfig/contextpath-prd.json'
        }
        fetch(this.url,{
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
          })
        .then(res => res.json())
        .then(data => {
            localStorage.setItem('QLep', atob(data.graphQlendpoint));
            localStorage.setItem("bannerURL", data.bannerURL);
        })
    }
    render() {
         const value = {}
        //return <AuthContext.Provider value={value}>{this.props.children}</AuthContext.Provider>;
        return <AuthContext.Provider value={value}>{this.props.children}</AuthContext.Provider>;

    }
}