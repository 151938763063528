import React, { useState } from "react";
import ReactDOM from 'react-dom';
import { Row, Button } from "react-bootstrap";
import "./ServiceDetailsResolved.scss";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
//import { Calendar } from "primereact/calendar";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const ServiceDetailsResolved = (props) => {
  let history = useNavigate();

  //Work Report Modal
  const [show, setShowReport] = useState(false);
  const handleCloseInput = () => setShowReport(false);

  const goBack = () => {
    history(-1);
  };

  const openWorkReport = () => {
    setShowReport(true);
  };

  return (
    <div className="service-details-wrapper">
      <div className="header-section">
        <span
          onClick={() => goBack()}
          className="backIcon"
          style={{ color: "blue" }}
        >
          <MdArrowBack />
        </span>
        <h5 className="app-header">TRITON GUARD</h5>
      </div>

      <div className="title">
        <div className="request-header">Request Details</div>
        <div className="welcome">
          <div className="user-box">
            <img src="https://picsum.photos/200/300?random=3" alt="" />
          </div>
          <div className="vessel-name">Vessel 2</div>
          <div className="vessel-number">IMO : 12345</div>
        </div>
      </div>
      <div className="issue-description-wrapper">
        <Row className="issue-container">
          <div className="issue-description">Abnormal Sound from Engine</div>
          <div className="part-specification">Main Engine</div>
          <div className="issue-time-status">
            <div className="issue-timestamp">5th July 2021</div>
            <div className="issue-status-description">
              Completed
            </div>
          </div>
          <div className="detailed-description">
            <div className="description-header">Description</div>
            <div className="description-details">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
            <div className="description-images-complete">
              <img
                src="https://picsum.photos/200/300?random=1"
                alt="image1"
                width="100"
                height="50"
              ></img>
              <img
                src="https://picsum.photos/200/300?random=2"
                alt="image2"
                width="100"
                height="50"
              ></img>
            </div>
          </div>
        </Row>
      </div>
      <div className="workflow-wrapper">
        <div className="workflow-header">Workflow</div>
        <div className="workflow-steps-form-resolved">
          <div className="step-summary">
            <div className="worflow-diagram">
              <div className="workflow-round">
              <div className="workflow-round-completed"></div>
              </div>
              <div className="workflow-arrow"></div>
            </div>
            <div className="step-details">
              <div className="step-description">Estimate Time and Date</div>
              <div className="step-timestamp">5th May 2021,5:20 PM</div>
            </div>
            <div className="step-approval-resolved">
              <div className="step-approver-details">
                Julia ,Service Provider
              </div>
              <div className="step-status">Completed</div>
            </div>
            <div className="step-comment">
              <div className="step-comment-text">"Tentative Estimate specified"</div>
            </div>
          </div>
          <div className="step-summary">
            <div className="worflow-diagram">
              <div className="workflow-round">
              <div className="workflow-round-completed"></div>
              </div>
              <div className="workflow-arrow"></div>
            </div>
            <div className="step-details">
              <div className="step-description">Remote Access Approval</div>
              <div className="step-timestamp">5th May 2021,5:20 PM</div>
            </div>
            <div className="step-approval-resolved">
              <div className="step-approver-details">James ,Captain</div>
              <div className="step-status">Completed</div>
            </div>
            <div className="step-comment">
              <div className="step-comment-text">"Approved"</div>
            </div>
          </div>
          <div className="step-summary">
            <div className="worflow-diagram">
              <div className="workflow-round"><div className="workflow-round-completed"></div></div>
              <div className="workflow-arrow"></div>
            </div>
            <div className="step-details">
              <div className="step-description">Report Work</div>
              <div className="step-timestamp">5th May 2021,5:20 PM</div>
            </div>
            <div className="step-approval-resolved">
              <div className="step-approver-details">
                Julia,Service Provider
              </div>
              <div className="step-status">Completed</div>
            </div>
            <div className="step-workreport" onClick={()=>openWorkReport()} >
              <div className="view-report">
                Click to View Work Report
                </div>
            </div>
          </div>
          <div className="step-summary">
            <div className="worflow-diagram">
              <div className="workflow-round"><div className="workflow-round-completed"></div></div>
              <div className="workflow-arrow"></div>
            </div>
            <div className="step-details">
              <div className="step-description">Review Work</div>
              <div className="step-timestamp">5th May 2021,5:20 PM</div>
            </div>
            <div className="step-approval-resolved">
              <div className="step-approver-details">
                James,Captain
              </div>
              <div className="step-status">Completed</div>
            </div>
            <div className="step-comment">
              <div className="step-comment-text">"Reviewed from my end"</div>
            </div>
          </div>
        </div>
      </div>
      <div className="workreport-modal-wrapper">
        <Modal
          className="workreport-modal"
          show={show}
          onHide={handleCloseInput}
          animation={false}
          backdrop={false}
          style={{ background: "#0000004d"}}
        >
          <Modal.Header closeButton>WORK REPORT</Modal.Header>
          <Modal.Body>
          <div className="work-report-wrapper">
              <div className="step-details">
                <div class="title-header">Title:</div>
                <div className="step-title">Loud Knocking Sounds</div>
                <div class="description-header">Description:</div>
                <div className="step-description">
                  Greasing had to be done to avoid abrasion
                </div>
                <div className="description-images-pending">
                  <img
                    src="https://picsum.photos/200/300?random=1"
                    alt="image1"
                    width="100"
                    height="50"
                  ></img>
                  <img
                    src="https://picsum.photos/200/300?random=2"
                    alt="image2"
                    width="100"
                    height="50"
                  ></img>
                </div>
              </div>
              <div className="step-approval">
                <div className="step-status">Completed by:</div>
                <div className="step-approver-details">
                  Julia , Service Provider
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default ServiceDetailsResolved;
