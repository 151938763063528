import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import {
  InteractionStatus
} from "@azure/msal-browser";
import {
  useMsal,
} from "@azure/msal-react";

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const [authState, setAuthState] = useState(false);
  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
  }, [instance, accounts, inProgress]);

  useEffect(() => {
    setAuthState(isAuthenticated)
  }, [isAuthenticated]);

  if (authState) {
    return children;
  } else {
    return (
      <>
        <div className="unauthorized">
          {
            inProgress == "logout" ? <p>Logging out...</p> : inProgress == "none" ? <p>Please wait...</p> : <>
              <p>You are not authenticated. Please proceed Login {inProgress}</p>
              <Link to='/' className="link">Go To Login</Link></>
          }
        </div>
      </>
    );
  }
};

export default ProtectedRoute;
