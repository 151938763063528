const Constants = {
  authority:
    window.location.href.indexOf("white") !== -1 ||
    window.location.href.indexOf("3000") !== -1
      ? "f45bbe90-9957-4ed7-810f-11c382518756"
      : window.location.href.indexOf("yellow") !== -1 ||
        window.location.href.indexOf("acc") !== -1
      ? "3eb8fc14-770c-4ab5-a44d-6151fcb833b2"
      : "b4329e2f-88b3-47d5-82fa-1b4e3ce106cd",
  clientId:
    window.location.href.indexOf("white") !== -1 ||
    window.location.href.indexOf("3000") !== -1
      ? "9e9007bf-d363-4433-9d4f-c28c616750ac"
      : window.location.href.indexOf("yellow") !== -1 ||
        window.location.href.indexOf("acc") !== -1
      ? "b9f0414b-b3be-4d8b-bc17-7a330f6597a5"
      : "62136a2a-2f6f-468a-8afd-5bbedf40f337",
  redirectUrl:
    window.location.href.indexOf("3000") !== -1
      ? "http://localhost:3000/"
      : window.location.href.indexOf("white") !== -1
      ? "https://white-glacier-034ea7803.2.azurestaticapps.net/"
      : window.location.href.indexOf("yellow") !== -1 ||
        window.location.href.indexOf("acc") !== -1
      ? "https://guard-acc.triton.damen.com/"
      : "https://guard.triton.damen.com/",
};

export default Constants;
