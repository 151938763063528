import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import "./login.scss";
import logo from "../../assets/LargeLogo.png";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../providers/AuthProvider";
import { Oval } from "react-loader-spinner";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { loginRequest } from "../../utils/authConfig";

const Login = (props) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
  let history = useNavigate();
  const [loginDisplay, setInProgress] = useState(false);
  const [loggedIn, setloggedIn] = useState(false);
  const [test, setTest] = useState(false);

  useEffect(() => {
    setInProgress(false);
    setloggedIn(false);
    // instance.handleRedirectPromise().then((resp) => {
    //   console.log('setting account')
    //   if (resp && resp.account) instance.setActiveAccount(resp.account);
    // });
  }, []);

  useEffect(() => {
    const accessTokenRequest = {
      scopes: ["user.read"],
      account: accounts[0],
    };
    // if (inProgress === InteractionStatus.None) {
    //   instance
    //     .acquireTokenSilent(accessTokenRequest)
    //     .then((accessTokenResponse) => {
    //       let accessToken = accessTokenResponse.accessToken;
    //       localStorage.setItem(
    //         "user_email",
    //         accessTokenResponse.idTokenClaims.preferred_username
    //       );
    //       localStorage.setItem(
    //         "Client",
    //         accessTokenResponse["idTokenClaims"]["aud"]
    //       );
    //     })
    //     .catch((error) => {
    //       if (error instanceof InteractionRequiredAuthError) {
    //         console.log("InteractionRequiredAuthError"); 
    //         instance
    //           .acquireTokenPopup(accessTokenRequest)
    //           .then(function (accessTokenResponse) {
    //             let accessToken = accessTokenResponse.accessToken;
    //             localStorage.setItem(
    //               "user_email",
    //               accessTokenResponse.idTokenClaims.preferred_username
    //             );
    //             localStorage.setItem(
    //               "Client",
    //               accessTokenResponse["idTokenClaims"]["aud"]
    //             );
    //             return accessToken; 
    //           })
    //           .catch(function (error) {
    //             // Acquire token interactive failure
    //             instance.logoutRedirect({
    //               postLogoutRedirectUri: "/",
    //             });
    //           });
    //       }
    //       console.log(error);
    //     });
    // }
  }, [instance, accounts, inProgress]);

  useEffect(() => {
    if (isAuthenticated) {
      console.log('serviceRequest')
      history("/serviceRequestList");
    }
  }, [isAuthenticated]);

  const userLogin = () => {
    setInProgress(true);
    setloggedIn(true);
    instance.loginRedirect(loginRequest).catch((e) => {});
  };

  return (
    <div className="App">
      <>
        <div className="head">
          <img className="logo" src={logo} alt="logo" />
          <h1 className="title">GUARD</h1>
        </div>
        <>
          {/* {!isAuthenticated ? */}
          <div className="body">
            {inProgress != "none" ? (
              <div className="auth-text">Loading..</div>
            ) : (
              <Button className="login-btn" onClick={userLogin}>
                Login
              </Button>
            )}
          </div>
        </>
      </>
    </div>
  );
};

export default Login;
