import {
    gql
  } from "@apollo/client";

export const Vessel_query = gql`
query vesselsByCustomer($id:ID!){
    vesselsByCustomer(id: $id){
        id
        name
        imo
        mmsi
        description
        imageURL
        typeDescription
        displayName
        contactNumber
        timezone
    }
}`;

